import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CategoryCellService, CategoryElementEventType} from './category-cell.service';
import {ActionCellEvent, ActionCellEventType, ActionCellService} from '../action-cell/action-cell.service';
import {filter, Subject, takeUntil} from 'rxjs';
import {PropertyDataService} from '../../../../services/property-data.service';
import {PropertyKey} from '../../../../models/fileKeyProperties.model';
import {TagCellEvent, TagCellEventType, TagCellService} from '../tag-cell/tag-cell.service';
import {CustomTranslateService} from '../../../../services/custom-translate.service';
import {HandleErrorService} from '../../../../services/handle-error.service';

export interface CategoryCellDto {
  global: boolean;
  key: {
    key: string,
    id: number,
  };
}

export enum CategoryCellState {
  READ_LOCAL,
  READ_LOCAL_DISABLED,
  READ_GLOBAL,
  CREATE,
  EDIT,
}

@Component({
             selector: 'dms-category-element',
             templateUrl: './category-cell.component.html',
             styleUrls: ['./category-cell.component.css']
           })
export class CategoryCellComponent implements OnDestroy, OnInit {

  private readonly stop$ = new Subject<void>();

  @Input() categoryCellDto?: CategoryCellDto;
  protected categoryCell: CategoryCellDto;
  protected currentCategoryValue?: string;
  protected state: CategoryCellState;

  constructor(private readonly categoryCellService: CategoryCellService,
              private readonly actionCellService: ActionCellService,
              private readonly tagCellService: TagCellService,
              private readonly translateService: CustomTranslateService,
              private readonly handleErrorService: HandleErrorService,
              private readonly propertyService: PropertyDataService) {
  }

  ngOnInit() {
    this.setCategoryCell(this.categoryCellDto);
    this.subscribeTagCellEvent();
    this.subscribeActionCellEvent();
    if (this.categoryCellDto === null) {
      this.setState(CategoryCellState.CREATE);
      this.setCurrentCategoryValue('');
    } else {
      this.setState(this.global ?
                    CategoryCellState.READ_GLOBAL :
                    CategoryCellState.READ_LOCAL);
      this.setCurrentCategoryValue(this.categoryName);
    }
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }

  private readonly setCategoryCell = (value: CategoryCellDto): void => {
    this.categoryCell = value;
  }

  private readonly subscribeTagCellEvent = (): void => {
    this.tagCellService
        .tagCellEvent$()
        .pipe(takeUntil(this.stop$),
              filter(this.concernsComponent))
        .subscribe(this.onTagCellEvent);
  }

  private readonly onTagCellEvent = (event: TagCellEvent): void => {
    if (event.type === TagCellEventType.NEW_TAGS_ADDED) {
      this.onNewTagsAddedEvent();
    }
  }

  private readonly onNewTagsAddedEvent = (): void => {
    this.setState(this.categoryCellDto.global ?
                  CategoryCellState.READ_GLOBAL :
                  CategoryCellState.READ_LOCAL);
  }

  private readonly setState = (value: CategoryCellState): void => {
    this.state = value;
  }

  private readonly subscribeActionCellEvent = (): void => {
    this.actionCellService
        .actionCellEvent$()
        .pipe(takeUntil(this.stop$),
              filter(this.concernsComponent))
        .subscribe(this.onActionCellEvent);
  }

  private readonly concernsComponent = (event: ActionCellEvent | TagCellEvent): boolean => {
    return event.key?.id === this.id;
  }

  private readonly onActionCellEvent = (event: ActionCellEvent): void => {
    switch (event.type) {
      case ActionCellEventType.CATEGORY_EDITION_SUBMIT:
        this.onSubmitCategoryEdition();
        break;
      case ActionCellEventType.CATEGORY_EDITION_CANCEL:
        this.onCancelCategoryEdition();
        break;
      case ActionCellEventType.PROPERTY_CREATION_SUBMIT:
        this.onSubmitPropertyCreation();
        break;
    }
  }

  private readonly onSubmitCategoryEdition = (): void => {
    this.propertyService
        .updatePropertyKey$(this.id, {
          id: this.id,
          global: false,
          key: this.currentCategoryValue
        })
        .pipe(takeUntil(this.stop$))
        .subscribe({
                     next: this.onUpdatePropertyKey,
                     error: this.onUpdatePropertyKeyError
                   });
  }

  private readonly onUpdatePropertyKeyError = (message: {error: {errorId: string}}): void => {
    const errorMessage = message.error.errorId === '3' ? 'duplicateCategoryError' : 'unableToEditCategoryError';
    this.handleErrorService.displayErrorDialog(this.translateService.getTranslatedValue(errorMessage), '');
    this.setCurrentCategoryValue(this.categoryName);
    this.setState(CategoryCellState.READ_LOCAL);
  }

  private readonly onUpdatePropertyKey = ({key}: PropertyKey): void => {
    this.setCategoryCell({
                           ...this.categoryCell,
                           key: {
                             ...this.categoryCell.key,
                             key: key
                           }
                         });
    this.setCurrentCategoryValue(key);
    this.setState(CategoryCellState.READ_LOCAL);
  }

  private readonly setCurrentCategoryValue = (value?: string): void => {
    this.currentCategoryValue = value;
  }

  protected displayEditButton(): boolean {
    return [CategoryCellState.READ_LOCAL,
            CategoryCellState.READ_LOCAL_DISABLED].includes(this.state);
  }

  protected displayCategoryTextInput(): boolean {
    return [CategoryCellState.CREATE,
            CategoryCellState.EDIT].includes(this.state);
  }

  protected onEditIconClick(): void {
    this.setState(CategoryCellState.EDIT);
    this.categoryCellService.publishCategoryElementEvent({
                                                           type: CategoryElementEventType.CATEGORY_EDIT_REQUEST,
                                                           key: {
                                                             id: this.id
                                                           }
                                                         });
    this.categoryCellService.publishCategoryElementEvent({
                                                           type: this.currentCategoryValue.length === 0 ?
                                                                 CategoryElementEventType.CATEGORY_WITHOUT_TEXT :
                                                                 CategoryElementEventType.CATEGORY_WITH_TEXT,
                                                           key: {
                                                             id: this.id
                                                           }
                                                         });
  }

  protected onTextInputChange(text: string): void {
    text = text.trim();
    if (this.currentCategoryValue.length === 0 && text.length > 0) {
      this.categoryCellService.publishCategoryElementEvent({
                                                             type: CategoryElementEventType.CATEGORY_WITH_TEXT,
                                                             key: {
                                                               id: this.id
                                                             }
                                                           });
    } else if (this.currentCategoryValue.length > 0 && text.length === 0) {
      this.categoryCellService.publishCategoryElementEvent({
                                                             type: CategoryElementEventType.CATEGORY_WITHOUT_TEXT,
                                                             key: {
                                                               id: this.id
                                                             }
                                                           });
    }
    this.setCurrentCategoryValue(text);
  }

  private readonly onCancelCategoryEdition = (): void => {
    this.setCurrentCategoryValue(this.categoryName);
    this.setState(CategoryCellState.READ_LOCAL);
  }

  private readonly onSubmitPropertyCreation = (): void => {
    this.categoryCellService.publishCategoryElementEvent({
                                                           type: CategoryElementEventType.CATEGORY_SUBMIT,
                                                           key: {
                                                             key: this.currentCategoryValue,
                                                           }
                                                         });
  }

  protected get id(): number {
    return this.categoryCell?.key.id;
  }

  protected get categoryName(): string {
    return this.categoryCell?.key.key;
  }

  protected get global(): boolean {
    return this.categoryCell?.global;
  }
}
