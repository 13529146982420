import {Component, EventEmitter, Host, Input, OnDestroy, OnInit, Optional, Output} from '@angular/core';
import {PropertyDataService} from '../../../../services/property-data.service';
import {PropertyValue} from '../../../../models/fileKeyProperties.model';
import {map, tap} from 'rxjs/operators';
import {TagCellComponent} from '../tag-cell/tag-cell.component';
import {CustomTranslateService} from '../../../../services/custom-translate.service';
import {HandleErrorService} from '../../../../services/handle-error.service';
import {DeletionConfirmationDialogComponent} from '../deletion-confirmation-dialog/deletion-confirmation-dialog.component';
import {Subject, switchMap, takeUntil} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';

export interface ValueSnapshot {
  value: PropertyValue;
}

@Component({
             selector: 'dms-tag-editable-chip',
             templateUrl: './tag-editable-chip.component.html',
             styleUrls: ['./tag-editable-chip.component.scss']
           })
export class TagEditableChipComponent implements OnInit, OnDestroy {

  private readonly SHOW_DELETION_TAG_DIALOG_KEY = 'show-deletion-tag-dialog';
  private readonly stop$ = new Subject<void>();

  @Input() protected value: PropertyValue;
  @Input() protected keyId: number;
  @Output() editChip = new EventEmitter<ValueSnapshot>();
  @Output() deleteChip = new EventEmitter<ValueSnapshot>();
  protected editMode: boolean;
  protected tagName: string;

  constructor(private readonly propertyService: PropertyDataService,
              private readonly translateService: CustomTranslateService,
              private readonly handleErrorService: HandleErrorService,
              @Host() @Optional() private readonly tagCell: TagCellComponent,
              public readonly dialog: MatDialog) {
  }

  ngOnInit() {
    this.setTagName(this.value.value);
    this.setEditMode(false);
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  private readonly setEditMode = (value: boolean): void => {
    this.editMode = value;
  }

  private readonly setTagName = (value: string): void => {
    this.tagName = value;
  }

  protected onTagTextInputChange(text: string): void {
    this.setTagName(text);
  }

  protected onDeleteIconClick(): void {
    const showDialog = (localStorage.getItem(this.SHOW_DELETION_TAG_DIALOG_KEY) ?? 'true') === 'true';
    let deletion$;
    if (showDialog) {
      deletion$ = this.dialog
                      .open(DeletionConfirmationDialogComponent, {
                        data: {
                          title: 'deleteTagConfirmationTitle',
                          message: 'deleteTagConfirmationMessage',
                          subMessage: 'deleteTagConfirmationSubMessage'
                        },
                      }).componentInstance
                      .deletion$()
                      .pipe(takeUntil(this.stop$),
                            tap(({showAgain}) => {
                              localStorage.setItem(this.SHOW_DELETION_TAG_DIALOG_KEY, showAgain ? 'true' : 'false');
                            }),
                            switchMap(() => this.propertyService.deletePropertyValue$(this.keyId, this.value.id)),
                            map(() => {
                              return {
                                value: this.value,
                              };
                            }));
    } else {
      deletion$ = this.propertyService.deletePropertyValue$(this.keyId, this.value.id)
                      .pipe(takeUntil(this.stop$),
                            map(() => {
                              return {
                                value: this.value,
                              };
                            }));
    }
    deletion$.subscribe({
                          next: (snapshot) => this.deleteChip.emit(snapshot),
                          error: this.onPropertyValueDeletionError
                        });
  }

  private readonly onPropertyValueDeletionError = (): void => {
    this.handleErrorService.displayErrorDialog(this.translateService.getTranslatedValue('unableToDeleteTag'), '');
  }

  protected onEditIconClick(): void {
    this.setEditMode(true);
  }

  private readonly setPropertyValue = (propertyValue: PropertyValue): void => {
    this.value = propertyValue;
  }

  protected onCancelButtonClick(): void {
    this.setTagName(this.value.value);
    this.setEditMode(false);
  }

  protected onSubmitButtonClick(): void {
    const nextValue = {id: this.value.id, value: this.tagName};
    this.propertyService
        .editPropertyValue$(this.keyId, nextValue)
        .subscribe({
                     next: this.onPropertyValueEdition,
                     error: this.onPropertyValueEditionError
                   });
  }

  private readonly onPropertyValueEditionError = (): void => {
    this.handleErrorService.displayErrorDialog(this.translateService.getTranslatedValue('unableToDeleteCategory'), '');
  }

  private readonly onPropertyValueEdition = (propertyValue: PropertyValue): void => {
    this.setPropertyValue(propertyValue);
    this.setTagName(propertyValue.value);
    this.setEditMode(false);
    this.editChip.emit({
                         value: propertyValue,
                       });
  }

  protected isSubmitButtonEnabled(): boolean {
    return this.tagName !== this.value.value &&
           this.tagName.trim().length > 0 &&
           !this.tagCell.containsTag(this.tagName);
  }
}
