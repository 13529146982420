import {CustomToastComponent} from './shared/custom-toast/custom-toast.component';
import {ToastContainerModule, ToastrModule} from 'ngx-toastr';
import {DriverService} from '../services/driver.service';
import {UserService} from '../services/user.service';
import {CustomerService} from '../services/customer.service';
import {DocMgmtService} from '../services/doc-mgmt.service';
import {TerminalService} from '../services/terminal.service';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DocumentDashboardComponent} from './document-dashboard/document-dashboard.component';
import {UnitComponent} from './document-dashboard/unit/unit.component';
import {FileComponent} from './document-dashboard/file/file.component';
import {GroupComponent} from './document-dashboard/group/group.component';
import {UploadComponent} from './document-dashboard/upload/upload.component';
import {SharedModule} from './shared/shared.module';
import {FileDetailComponent} from './document-dashboard/file-detail/file-detail.component';
import {UnitDetailComponent} from './document-dashboard/unit-detail/unit-detail.component';
import {GroupDetailComponent} from './document-dashboard/group-detail/group-detail.component';
import {IncomingDocumentsComponent} from './document-dashboard/incoming-documents/incoming-documents.component';
import {TidLoginComponent} from '../tid-login/tid-login.component';
import {ErrorComponent} from '../error/error.component';
import {AioHeaderComponent} from '../aio-header/aio-header.component';
import {AioSideNavComponent} from '../aio-side-nav/aio-side-nav.component';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {TagComponent} from './document-dashboard/tag/tag.component';
import {TagTableComponent} from './document-dashboard/tag/tag-table/tag-table.component';
import {TagHeaderComponent} from './document-dashboard/tag/tag-header/tag-header.component';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatMenuModule} from '@angular/material/menu';
import { TagEditableChipComponent } from './document-dashboard/tag/tag-editable-chip/tag-editable-chip.component';
import { TagCreatableChipComponent } from './document-dashboard/tag/tag-creatable-chip/tag-creatable-chip.component';
import { TagCellComponent } from './document-dashboard/tag/tag-cell/tag-cell.component';
import { CategoryCellComponent } from './document-dashboard/tag/category-cell/category-cell.component';
import { ActionCellComponent } from './document-dashboard/tag/action-cell/action-cell.component';
import {TagHeaderService} from './document-dashboard/tag/tag-header/tag-header.service';
import {CategoryCellService} from './document-dashboard/tag/category-cell/category-cell.service';
import {ActionCellService} from './document-dashboard/tag/action-cell/action-cell.service';
import {TagCellService} from './document-dashboard/tag/tag-cell/tag-cell.service';
import { DeletionConfirmationDialogComponent } from './document-dashboard/tag/deletion-confirmation-dialog/deletion-confirmation-dialog.component';
import {MatListModule} from '@angular/material/list';
import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
  declarations: [
    DocumentDashboardComponent,
    UnitComponent,
    FileComponent,
    GroupComponent,
    UploadComponent,
    FileDetailComponent,
    UnitDetailComponent,
    GroupDetailComponent,
    CustomToastComponent,
    IncomingDocumentsComponent,
    TidLoginComponent,
    ErrorComponent,
    AioHeaderComponent,
    AioSideNavComponent,
    TagComponent,
    TagTableComponent,
    TagHeaderComponent,
    TagEditableChipComponent,
    TagCreatableChipComponent,
    TagCellComponent,
    CategoryCellComponent,
    ActionCellComponent,
    DeletionConfirmationDialogComponent,
  ],
  exports: [DocumentDashboardComponent],
            imports: [
              CommonModule,
              SharedModule,
              ToastrModule.forRoot({
                                     maxOpened: 3,
                                     timeOut: 3000,
                                     preventDuplicates: true,
                                     toastComponent: CustomToastComponent, // custom toast
                                   }),
              ToastContainerModule,
              MatSelectModule,
              MatIconModule,
              MatSortModule,
              MatTableModule,
              MatMenuModule,
              MatListModule,
              MatCheckboxModule,
            ],
  providers: [
    DocMgmtService,
    TerminalService,
    UserService,
    DriverService,
    CustomerService,
    TagHeaderService,
    CategoryCellService,
    ActionCellService,
    TagCellService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DocumentModule {
}
