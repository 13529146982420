import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {filter, Subject, takeUntil, tap} from 'rxjs';
import {CategoryCellService, CategoryElementEvent, CategoryElementEventType} from '../category-cell/category-cell.service';
import {PropertyDataService} from '../../../../services/property-data.service';
import {PropertyKey, PropertyValue} from '../../../../models/fileKeyProperties.model';
import {TagCellEventType, TagCellService} from './tag-cell.service';
import {CustomTranslateService} from '../../../../services/custom-translate.service';
import {ValueSnapshot} from '../tag-editable-chip/tag-editable-chip.component';
import {ActionCellEvent, ActionCellEventType, ActionCellService} from '../action-cell/action-cell.service';
import {TagCollection} from './tag-collection';
import {HandleErrorService} from '../../../../services/handle-error.service';


export enum TagCellState {
  READ_GLOBAL,
  READ_LOCAL,
  TAG_CREATION,
  TAG_ADDITION
}

export interface TagCellDto {
  global: boolean;
  key?: {
    id: number
  };
  values?: {
    id: number,
    value: string,
  }[];
}

@Component({
             selector: 'dms-tag-cell',
             templateUrl: './tag-cell.component.html',
             styleUrls: ['./tag-cell.component.scss']
           })
export class TagCellComponent implements OnInit, OnDestroy, AfterViewInit {

  private readonly TOTAL_TAGS_THRESHOLD = 100;
  private readonly CHIP_OFFSET_SIZE_GLOBAL = 45;
  private readonly CHIP_OFFSET_SIZE_LOCAL = 100;
  private readonly stop$ = new Subject<void>;

  @Input() tagCellDto?: TagCellDto;
  protected tagCell: TagCellDto;

  @ViewChild('parentElement') protected parentElement: ElementRef;

  private tagCollection: TagCollection;
  private cachedOverviewTags: PropertyValue[] = [];
  private cachedTags: PropertyValue[] = [];

  protected totalOverviewTags: number;
  protected state: TagCellState;
  protected currentTagName: string;
  protected expanded: boolean;

  constructor(private readonly tagCellService: TagCellService,
              private readonly categoryCellService: CategoryCellService,
              private readonly actionCellService: ActionCellService,
              private readonly propertyDataService: PropertyDataService,
              private readonly translateService: CustomTranslateService,
              private readonly handleErrorService: HandleErrorService,
              private readonly renderer: Renderer2,
              private readonly cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.tagCollection = new TagCollection(this.tagCellDto?.values);
    this.setTagCell(this.tagCellDto);
    this.setCurrentTagName('');
    this.setExpanded(false);
    if (this.tagCellDto === null) {
      this.setState(TagCellState.TAG_CREATION);
    } else {
      this.setState(this.tagCellDto.global ? TagCellState.READ_GLOBAL : TagCellState.READ_LOCAL);
    }
    this.subscribeCategoryElementEvent();
    this.subscribeActionElementEvent();
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }

  ngAfterViewInit(): void {
    if (this.tagCellDto !== null) {
      this.setVisiblePropertyValues();
      this.cachedTags = this.tagCollection.getExistingTags();
      this.cachedOverviewTags = this.cachedTags.slice(0, this.totalOverviewTags);
      setTimeout(() => {
        this.tagCellService.publishTagCellEvent({
                                                  type: (this.tagCollection.size() >= this.TOTAL_TAGS_THRESHOLD) ?
                                                        TagCellEventType.NOT_MORE_TAGS_ALLOWED :
                                                        TagCellEventType.MORE_TAGS_ALLOWED,
                                                  key: {
                                                    id: this.keyId
                                                  }
                                                });
      }, 0);
    }
  }

  private readonly subscribeCategoryElementEvent = (): void => {
    this.categoryCellService
        .categoryElementEvent$()
        .pipe(takeUntil(this.stop$),
              filter(this.concernsComponent))
        .subscribe(this.onCategoryElementEvent);
  }

  private readonly onCategoryElementEvent = (event: CategoryElementEvent): void => {
    if (event.type === CategoryElementEventType.CATEGORY_SUBMIT) {
      this.onCategorySubmitEvent(event);
    }
  }

  private readonly onCategorySubmitEvent = (event: CategoryElementEvent): void => {
    this.propertyDataService
        .createPropertyKey$(event.key.key, this.tagCollection.getPendingTagsNames())
        .pipe(takeUntil(this.stop$))
        .subscribe({
                     next: this.onPropertyCreation,
                     error: this.onPropertyCreationError,
                   });
  }

  private readonly setVisiblePropertyValues = (): void => {
    const parent = this.parentElement.nativeElement;
    const virtualParent = this.buildVirtualParent();
    let totalWidth = 0;
    let totalOverviewTags = 0;
    const offsetWidth = this.global ? this.CHIP_OFFSET_SIZE_GLOBAL : this.CHIP_OFFSET_SIZE_LOCAL;
    for (const tag of this.tagCollection.getExistingTags()) {
      const chipWidth = this.calculateChipWidth(virtualParent, tag.value, offsetWidth);
      if (totalWidth + chipWidth >= parent.offsetWidth) {
        break;
      }
      totalWidth += chipWidth;
      totalOverviewTags++;
    }
    const remaining = this.tagCollection.existingTagsSize() - totalOverviewTags;
    if (remaining > 0) {
      const remainingChipWidth = this.calculateChipWidth(virtualParent, `+ ${remaining}`, this.CHIP_OFFSET_SIZE_LOCAL);
      if (totalWidth + remainingChipWidth >= parent.offsetWidth) {
        totalOverviewTags--;
      }
    }
    this.setTotalOverviewTags(totalOverviewTags);
    this.renderer.removeChild(document.body, virtualParent);
  }

  private updateCachedTags(): void {
    this.cachedTags = this.tagCollection.getExistingTags();
    this.cachedOverviewTags = this.cachedTags.slice(0, this.totalOverviewTags);
  }

  protected get overviewTags(): PropertyValue[] {
    return this.cachedOverviewTags;
  }

  protected get totalRemainingTags(): number {
    return this.tagCollection.existingTagsSize() - this.totalOverviewTags;
  }

  private readonly setTotalOverviewTags = (value: number): void => {
    this.totalOverviewTags = value;
  }

  private readonly setTagCell = (dto?: TagCellDto): void => {
    this.tagCell = dto !== null ? JSON.parse(JSON.stringify(dto)) : null;
  }

  private setExpanded = (value: boolean): void => {
    this.expanded = value;
  }

  @HostListener('window:resize')
  protected onResize() {
    this.setExpanded(false);
    this.setVisiblePropertyValues();
    this.updateCachedTags();
  }

  private readonly subscribeActionElementEvent = (): void => {
    this.actionCellService
        .actionCellEvent$()
        .pipe(takeUntil(this.stop$),
              filter(this.concernsComponent))
        .subscribe(this.onActionCellEvent);
  }

  private readonly concernsComponent = (event: CategoryElementEvent | ActionCellEvent): boolean => {
    return this.keyId === event.key?.id;
  }

  protected get keyId(): number {
    return this.tagCell?.key.id;
  }

  private readonly onActionCellEvent = (event: ActionCellEvent): void => {
    switch (event.type) {
      case ActionCellEventType.TAG_ADDITION_REQUEST:
        this.onTagAdditionRequestEvent();
        break;
      case ActionCellEventType.TAG_ADDITION_CANCEL:
        this.onTagAdditionCancelEvent();
        break;
      case ActionCellEventType.TAG_ADDITION_SUBMIT:
        this.onTagAdditionSubmitEvent();
        break;
    }
  }

  private readonly onTagAdditionRequestEvent = (): void => {
    this.tagCollection.resetPendingTags();
    this.setState(TagCellState.TAG_ADDITION);
  }

  private readonly onTagAdditionCancelEvent = (): void => {
    this.setState(this.global ? TagCellState.READ_GLOBAL : TagCellState.READ_LOCAL);
    this.tagCollection.resetPendingTags();
    this.setCurrentTagName('');
  }

  private readonly onTagAdditionSubmitEvent = (): void => {
    this.propertyDataService
        .createPropertyValues$(this.keyId, this.tagCollection.getPendingTagsNames())
        .pipe(takeUntil(this.stop$))
        .subscribe({
                     next: this.onTagAdditionSubmission,
                     error: this.onTagAdditionSubmissionError
                   });
  }

  private readonly onTagAdditionSubmissionError = ({errorId}: {errorId: number}): void => {
    this.handleErrorService.displayErrorDialog(this.translateService.getTranslatedValue('unableToCreateTagError'), '');
  }

  private readonly onTagAdditionSubmission = (values: PropertyValue[]): void => {
    this.setState(TagCellState.READ_LOCAL);
    this.tagCollection.addExistingTags(values);
    this.setCurrentTagName('');
    this.setVisiblePropertyValues();
    this.updateCachedTags();
    this.tagCellService.publishTagCellEvent({
                                                 type: TagCellEventType.NEW_TAGS_ADDED,
                                                 key: {
                                                   id: this.keyId,
                                                 }
                                               });
  }

  private readonly buildVirtualParent = (): Element => {
    const virtualParent = this.renderer.createElement('div');
    this.renderer.setStyle(virtualParent, 'visibility', 'hidden');
    this.renderer.setStyle(virtualParent, 'position', 'absolute');
    this.renderer.setStyle(virtualParent, 'white-space', 'nowrap');
    this.renderer.appendChild(document.body, virtualParent);
    return virtualParent;
  }

  private readonly calculateChipWidth = (parent: Element, text: string, offsetWidth: number): number => {
    const textElement = this.renderer.createText(this.translate(text));
    this.renderer.appendChild(parent, textElement);
    const result = parent.getBoundingClientRect().width + offsetWidth;
    this.renderer.removeChild(parent, textElement);
    return result;
  }

  private readonly translate = (text: string): string => {
    return this.translateService.canTranslate(text) ? this.translateService.getTranslatedValue(text) : text;
  }

  private readonly onPropertyCreation = (propertyKey: PropertyKey): void => {
    this.tagCellService
        .publishTagCellEvent({
                                  type: TagCellEventType.PROPERTY_CREATED,
                                  key: {
                                    id: propertyKey.id,
                                    key: propertyKey.key,
                                  },
                                  values: propertyKey.values?.map(({id, value}) => {
                                    return {id, value};
                                  }) ?? [],
                                });
  }

  private readonly onPropertyCreationError = (message: {error: {errorId: string}}): void => {
    const errorMessage = message.error.errorId === '3' ? 'duplicateCategoryError' : 'unableToCreateCategoryError';
    this.handleErrorService.displayErrorDialog(this.translateService.getTranslatedValue(errorMessage), '');
  }

  private readonly setState = (state: TagCellState): void => {
    this.state = state;
  }

  protected onAddButtonClick(): void {
    if (this.isAllowedToAddTag()) {
      if ([TagCellState.TAG_ADDITION, TagCellState.TAG_CREATION].includes(this.state)) {
        if (this.tagCollection.pendingTagsSize() === 0) {
          this.tagCellService.publishTagCellEvent({
                                                       type: TagCellEventType.CONTAINS_NEW_TAGS,
                                                       key: {
                                                         id: this.keyId
                                                       }
                                                     });
        }
        this.tagCollection.addPendingTagName(this.currentTagName);
        this.setCurrentTagName('');
        if (this.tagCollection.size() === this.TOTAL_TAGS_THRESHOLD) {
          this.tagCellService.publishTagCellEvent({
                                                       type: TagCellEventType.NOT_MORE_TAGS_ALLOWED,
                                                       key: {
                                                         id: this.keyId
                                                       }
                                                     });
        }
      }
    }
  }

  protected onTagTextInputChange(text: string): void {
    this.setCurrentTagName(text);
  }

  protected onChipCloseClick(value: string): void {
    if (this.tagCollection.size() === this.TOTAL_TAGS_THRESHOLD) {
      this.tagCellService.publishTagCellEvent({
                                                   type: TagCellEventType.MORE_TAGS_ALLOWED,
                                                   key: {
                                                     id: this.keyId
                                                   }
                                                 });
    }
    this.tagCollection.removeFromPending(value);
  }

  private readonly setCurrentTagName = (text: string): void => {
    this.currentTagName = text;
  }

  protected get global(): boolean {
    return this.tagCell?.global;
  }

  protected onDeleteChip({value}: ValueSnapshot): void {
    this.tagCollection.removeFromExisting(value.id);
    this.setVisiblePropertyValues();
    this.updateCachedTags();
    this.cdr.detectChanges();
    if (this.tagCollection.size() === this.TOTAL_TAGS_THRESHOLD) {
      this.tagCellService.publishTagCellEvent({
                                                   type: TagCellEventType.MORE_TAGS_ALLOWED,
                                                   key: {
                                                     id: this.keyId
                                                   }
                                                 });
    }
  }

  protected onEditChip({value}: ValueSnapshot): void {
    this.setPropertyValue(value);
  }

  private readonly setPropertyValue = ({id, value}: PropertyValue) => {
    this.tagCollection.replaceExistingTag(id, value);
    this.setVisiblePropertyValues();
    this.updateCachedTags();
    this.cdr.detectChanges();
  }

  protected onExpansionChipClick(): void {
    this.setExpanded(true);
  }

  protected onCloseTagClick(value: string) {
    if (this.state === TagCellState.TAG_ADDITION &&
        this.tagCollection.pendingTagsSize() === 1) {
      this.tagCellService.publishTagCellEvent({
                                                   type: TagCellEventType.EMPTY_NEW_TAGS,
                                                   key: {
                                                     id: this.keyId
                                                   }
                                                 });
    }
    this.tagCollection.removeFromPending(value);
  }

  protected onCollapseTagsClick(): void {
    this.setExpanded(false);
  }

  protected displayTagsWithExpansion(): boolean {
    return this.expanded || this.state === TagCellState.TAG_ADDITION;
  }

  protected get tags(): PropertyValue[] {
    return this.tagCollection.getExistingTags();
  }

  protected get pendingTags(): string[] {
    return this.tagCollection.getPendingTagsNames();
  }

  protected displayCollapseButton(): boolean {
    return this.state !== TagCellState.TAG_ADDITION;
  }

  protected displayTagAdditionElements(): boolean {
    return [TagCellState.TAG_CREATION,
            TagCellState.TAG_ADDITION].includes(this.state) &&
           this.tagCollection.size() < this.TOTAL_TAGS_THRESHOLD;
  }

  public containsTag(value: string): boolean {
    return this.tagCollection.includes(value);
  }

  protected trackTags(index: number, item: PropertyValue): number {
    return item.id;
  }

  protected trackPendingTags(index: number, item: string): string {
    return item;
  }

  protected isAllowedToAddTag(): boolean {
    return this.currentTagName.trim().length > 0 &&
           !this.tagCollection.includes(this.currentTagName);
  }
}
