<div class="dialog-content">
  <div>
    <h4>{{ "AssignTagsToDocument" | translate }}</h4>
    <h5>{{ data.fileName }}</h5>
  </div>
  <div class="custom-input-box">
    <div class="chips-container">
      <div class="chips-scroll">
        <span *ngFor="let value of selectedValues" class="chip-span">
          <modus-chip size="medium" show-close value="{{ value | translate }}" (closeClick)="logOptionValue(value, false)">
          </modus-chip>
        </span>
      </div>
      <input 
        #searchInput 
        type="text" 
        class="search-input"
        placeholder="{{ selectedValues.size === 0 ? ('SearchForPropertiesAndTags' | translate) : '' }}"
        (input)="onSearch(searchInput.value)">
    </div>
  </div>
  <div class="dialog-flex-content" 
       infiniteScroll 
       #scrollDiv 
       [infiniteScrollDistance]="1" 
       [infiniteScrollThrottle]="50"
       (scrolled)="onScroll($event)" 
       [scrollWindow]="false">
    <div class="checkbox-container">
      <mat-selection-list>
        <mat-list-option *ngFor="let option of filteredPropertyValues" 
                         [value]="option.value.value"
                         [selected]="selectedValues.has(option.value.value)" 
                         class="list-item">
          <div class="list-item-content">
            <mat-checkbox class="full-width-checkbox" 
                          [checked]="selectedValues.has(option.value.value)"
                          (change)="logOptionValue(option.value.value, $event.checked)">
              <div class="checkbox-content">
                <span class="checkbox-label">{{ option.value.value | translate }}</span>
              </div>
            </mat-checkbox>
            <span class="checkbox-key">{{ option.key | translate }}</span>
          </div>
        </mat-list-option>
      </mat-selection-list>
    </div>
  </div>
  <div class="dialog-footer">
    <modus-button color="tertiary" (click)="onCancel()">{{ "cancel" | translate }}</modus-button>
    <modus-button color="primary" type="submit" (click)="onAssign()">{{"assignTags" | translate }}</modus-button>
  </div>
</div>