import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {filter, Subject, switchMap, takeUntil} from 'rxjs';
import {ActionCellEventType, ActionCellService} from './action-cell.service';
import {CategoryCellService, CategoryElementEvent, CategoryElementEventType} from '../category-cell/category-cell.service';
import {PropertyDataService} from '../../../../services/property-data.service';
import {TagCellEvent, TagCellEventType, TagCellService} from '../tag-cell/tag-cell.service';
import {CustomTranslateService} from '../../../../services/custom-translate.service';
import {HandleErrorService} from '../../../../services/handle-error.service';
import {MatDialog} from '@angular/material/dialog';
import {DeletionConfirmationDialogComponent} from '../deletion-confirmation-dialog/deletion-confirmation-dialog.component';
import {tap} from 'rxjs/operators';


export interface ActionCellDto {
  global: boolean;
  key: {
    id: number;
  };
}

export enum ActionCellState {
  DEFAULT,
  PROPERTY_CREATION,
  CATEGORY_EDITION,
  TAG_ADDITION
}

@Component({
             selector: 'dms-action-element',
             templateUrl: './action-cell.component.html',
             styleUrls: ['./action-cell.component.scss']
           })
export class ActionCellComponent implements OnInit, OnDestroy {

  private readonly SHOW_DELETION_PROPERTY_DIALOG_KEY = 'show-deletion-property-dialog';

  private readonly stop$ = new Subject<void>();

  @Input() actionCellDto?: ActionCellDto;

  protected actionCell: ActionCellDto;
  protected state: ActionCellState;
  protected submitEnabled: boolean;
  protected tagAdditionEnabled: boolean;

  constructor(private readonly actionCellService: ActionCellService,
              private readonly categoryCellService: CategoryCellService,
              private readonly tagCellService: TagCellService,
              private readonly propertyDataService: PropertyDataService,
              private readonly translateService: CustomTranslateService,
              private readonly handleErrorService: HandleErrorService,
              public readonly dialog: MatDialog) {
  }

  ngOnInit() {
    this.setActionCell(this.actionCellDto);
    this.setState(this.actionCellDto === null ?
                  ActionCellState.PROPERTY_CREATION :
                  ActionCellState.DEFAULT);
    this.setTagAdditionEnabled(true);
    this.subscribeCategoryElementEvent();
    this.subscribeTagCellEvent();
  }

  private readonly setTagAdditionEnabled = (value: boolean): void => {
    this.tagAdditionEnabled = value;
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  private readonly setActionCell = (value: ActionCellDto): void => {
    this.actionCell = value;
  }

  private readonly subscribeCategoryElementEvent = (): void => {
    this.categoryCellService
        .categoryElementEvent$()
        .pipe(takeUntil(this.stop$),
              filter(this.eventConcernsComponent))
        .subscribe(this.onCategoryElementEvent);
  }

  private readonly onCategoryElementEvent = ({type}: CategoryElementEvent): void => {
    switch (type) {
      case CategoryElementEventType.CATEGORY_EDIT_REQUEST:
        this.setState(ActionCellState.CATEGORY_EDITION);
        break;
      case CategoryElementEventType.CATEGORY_WITH_TEXT:
        this.setSubmitEnabled(true);
        break;
      case CategoryElementEventType.CATEGORY_WITHOUT_TEXT:
        this.setSubmitEnabled(false);
        break;
    }
  }

  protected onSubmitButtonClick(): void {
    switch (this.state) {
      case ActionCellState.PROPERTY_CREATION:
        this.onSubmitCreationPropertyButtonClick();
        break;
      case ActionCellState.CATEGORY_EDITION:
        this.onSubmitCategoryEditionButtonClick();
        break;
      case ActionCellState.TAG_ADDITION:
        this.onSubmitAdditionTagButtonClick();
        break;
    }
  }

  private readonly onSubmitCreationPropertyButtonClick = (): void => {
    this.actionCellService.publishCellElementEvent({
                                                     type: ActionCellEventType.PROPERTY_CREATION_SUBMIT,
                                                   });

  }

  private readonly onSubmitCategoryEditionButtonClick = (): void => {
    this.setState(ActionCellState.DEFAULT);
    this.actionCellService.publishCellElementEvent({
                                                     type: ActionCellEventType.CATEGORY_EDITION_SUBMIT,
                                                     key: {
                                                       id: this.id,
                                                     }
                                                   });
  }

  protected onSubmitAdditionTagButtonClick(): void {
    this.setState(ActionCellState.DEFAULT);
    this.actionCellService
        .publishCellElementEvent({
                                   type: ActionCellEventType.TAG_ADDITION_SUBMIT,
                                   key: {
                                     id: this.id
                                   }
                                 });
  }

  private readonly subscribeTagCellEvent = (): void => {
    this.tagCellService
        .tagCellEvent$()
        .pipe(takeUntil(this.stop$),
              filter(this.eventConcernsComponent))
        .subscribe(this.onTagCellEvent);
  }

  private readonly eventConcernsComponent = ({key}: TagCellEvent | CategoryElementEvent): boolean => {
    return key?.id === this.actionCell?.key?.id;
  }

  private readonly onTagCellEvent = ({type}: TagCellEvent): void => {

    switch (type) {
      case TagCellEventType.CONTAINS_NEW_TAGS:
        this.setSubmitEnabled(true);
        break;
      case TagCellEventType.EMPTY_NEW_TAGS:
        this.setSubmitEnabled(false);
        break;
      case TagCellEventType.NOT_MORE_TAGS_ALLOWED:
        this.setTagAdditionEnabled(false);
        break;
      case TagCellEventType.MORE_TAGS_ALLOWED:
        this.setTagAdditionEnabled(true);
        break;
    }
  }

  protected onCancelButtonClick(): void {
    switch (this.state) {
      case ActionCellState.CATEGORY_EDITION:
        this.onCancelCategoryEditionButtonClick();
        break;
      case ActionCellState.PROPERTY_CREATION:
        this.onCancelCreationPropertyButtonClick();
        break;
      case ActionCellState.TAG_ADDITION:
        this.onCancelAdditionTagButtonClick();
        break;
    }
  }

  protected onCancelCategoryEditionButtonClick(): void {
    this.setState(ActionCellState.DEFAULT);
    this.actionCellService.publishCellElementEvent({
                                                     type: ActionCellEventType.CATEGORY_EDITION_CANCEL,
                                                     key: {
                                                       id: this.actionCellDto.key.id
                                                     }
                                                   });
  }

  protected onCancelCreationPropertyButtonClick(): void {
    this.setSubmitEnabled(false);
    this.actionCellService.publishCellElementEvent({
                                                     type: ActionCellEventType.PROPERTY_CREATION_CANCEL
                                                   });
  }

  protected onDeletionButtonClick(): void {
    const showDialog = (localStorage.getItem(this.SHOW_DELETION_PROPERTY_DIALOG_KEY) ?? 'true') === 'true';
    let deletion$;
    if (showDialog) {
      deletion$ = this.dialog
                      .open(DeletionConfirmationDialogComponent, {
                        data: {
                          title: 'deleteCategoryConfirmationTitle',
                          message: 'deleteCategoryConfirmationMessage',
                          subMessage: 'deleteCategoryConfirmationSubMessage'
                        },
                      }).componentInstance
                      .deletion$()
                      .pipe(takeUntil(this.stop$),
                            tap(({showAgain}) => {
                              localStorage.setItem(this.SHOW_DELETION_PROPERTY_DIALOG_KEY, showAgain ? 'true' : 'false');
                            }),
                            switchMap(() => this.propertyDataService.deletePropertyKey$(this.id)));
    } else {
      deletion$ = this.propertyDataService
                      .deletePropertyKey$(this.id)
                      .pipe(takeUntil(this.stop$));
    }
    deletion$.subscribe({
                          next: this.onDeletePropertyKey,
                          error: this.onDeletePropertyKeyError
                        });
  }

  private readonly onDeletePropertyKeyError = (): void => {
    this.handleErrorService.displayErrorDialog(this.translateService.getTranslatedValue('unableToDeleteCategory'), '');
  }

  private readonly onDeletePropertyKey = (): void => {
    this.setState(ActionCellState.TAG_ADDITION);
    this.actionCellService
        .publishCellElementEvent({
                                   type: ActionCellEventType.PROPERTY_DELETION_REQUEST,
                                   key: {
                                     id: this.id
                                   }
                                 });
  }

  protected onAdditionButtonClick(): void {
    this.setState(ActionCellState.TAG_ADDITION);
    this.actionCellService
        .publishCellElementEvent({
                                   type: ActionCellEventType.TAG_ADDITION_REQUEST,
                                   key: {
                                     id: this.id
                                   }
                                 });

  }

  protected onCancelAdditionTagButtonClick(): void {
    this.setState(ActionCellState.DEFAULT);
    this.actionCellService
        .publishCellElementEvent({
                                   type: ActionCellEventType.TAG_ADDITION_CANCEL,
                                   key: {
                                     id: this.id,
                                   }
                                 });
  }

  private readonly setSubmitEnabled = (value: boolean): void => {
    this.submitEnabled = value;
  }

  private readonly setState = (state: ActionCellState): void => {
    this.state = state;
  }

  protected get id(): number {
    return this.actionCell?.key?.id;
  }

  protected displayDefaultActions(): boolean {
    return this.state === ActionCellState.DEFAULT;
  }


}
