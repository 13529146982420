import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

export enum TagCellEventType {
  PROPERTY_CREATED,
  NEW_TAGS_ADDED,
  CONTAINS_NEW_TAGS,
  EMPTY_NEW_TAGS,
  NOT_MORE_TAGS_ALLOWED,
  MORE_TAGS_ALLOWED
}

export interface TagCellEvent {
  type: TagCellEventType;
  key?: {
    id: number,
    key?: string
  };
  values?: {
    id: number,
    value: string,
  }[];
  error?: {
    id: number;
  };
}

@Injectable()
export class TagCellService {

  private readonly tagCellEventSubject = new Subject<TagCellEvent>();

  public tagCellEvent$(): Observable<TagCellEvent> {
    return this.tagCellEventSubject.asObservable();
  }

  public publishTagCellEvent(event: TagCellEvent): void {
    this.tagCellEventSubject.next(event);
  }
}
