<div class="container">
  <div class="table-content"
       infiniteScroll
       #scrollDiv
       [infiniteScrollDistance]="1"
       [infiniteScrollThrottle]="50"
       (scrolled)="onScroll()"
       [scrollWindow]="false">

    <table mat-table
           matSort
           multiTemplateDataRows
           class="tag-table"
           [dataSource]=dataSource
           (matSortChange)="onSortChange($event)">

      <!-- property column -->
      <ng-container matColumnDef="property">
        <th mat-header-cell
            mat-sort-header
            *matHeaderCellDef>
          <b class="column-label">{{"category" | translate}}</b>
        </th>

        <td mat-cell *matCellDef="let property">
          <dms-category-element [categoryCellDto]="toCategoryCellDto(property)">
          </dms-category-element>
        </td>
      </ng-container>

      <!-- tag column -->
      <ng-container matColumnDef="tag">
        <th mat-header-cell
            *matHeaderCellDef>
          <b class="tags-column-label">{{"tags" | translate}}</b>
        </th>
        <td mat-cell *matCellDef="let property">
          <dms-tag-cell [tagCellDto]="toTagCellDto(property)">
          </dms-tag-cell>
        </td>
      </ng-container>

      <!-- action column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let property">
          <dms-action-element *ngIf="property === null || !property.global"
                              [actionCellDto]="toActionCellDto(property)">
          </dms-action-element>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let property; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
