<div mat-dialog-content>
  <b>{{title | translate}}</b>
</div>
<div style="font-size: 13px">
  <p>
    {{message | translate}}
  </p>
  <p>
    {{subMessage | translate}}
  </p>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions class="actions-container">
  <mat-checkbox value="checked"
                color="primary"
                (click)="changeValue(checked)">
    <span class="checkbox-text">
      {{'dontShowAgainCheckbox' | translate}}
    </span>
  </mat-checkbox>
  <div>
    <span class="button-container">
      <modus-button button-style="outline"
                    color="secondary"
                    size="small"
                    (buttonClick)="onCancelClick()">
      {{'cancel' | translate}}
    </modus-button>
    </span>
    <span class="button-container">
      <modus-button color="primary"
                    size="small"
                    (buttonClick)="onDeleteClick()">
        {{'deleteConfirmButton' | translate}}
      </modus-button>
    </span>
  </div>
</div>
