import {Injectable} from '@angular/core';
import * as translationEn from 'src/assets/i18n/en.json';
import * as translationCs from 'src/assets/i18n/cs.json';
import * as translationDa from 'src/assets/i18n/da.json';
import * as translationDe from 'src/assets/i18n/de.json';
import * as translationEs from 'src/assets/i18n/es.json';
import * as translationFr from 'src/assets/i18n/fr.json';
import * as translationIt from 'src/assets/i18n/it.json';
import * as translationNl from 'src/assets/i18n/nl.json';
import * as translationPl from 'src/assets/i18n/pl.json';
import * as translationPt from 'src/assets/i18n/pt.json';
import * as translationRu from 'src/assets/i18n/ru.json';
import * as translationSv from 'src/assets/i18n/sv.json';
import * as translationSi from 'src/assets/i18n/si.json';

const TRANSLATIONS = {
  en: translationEn,
  cs: translationCs,
  da: translationDa,
  de: translationDe,
  es: translationEs,
  fr: translationFr,
  it: translationIt,
  nl: translationNl,
  pl: translationPl,
  pt: translationPt,
  ru: translationRu,
  sv: translationSv,
  si: translationSi
} as Object;

const DEFAULT_LANGUAGE = 'en';

@Injectable({
  providedIn: 'root'
})
export class CustomTranslateService {
  langJson: any;

  constructor() {
    const storedLanguage = localStorage.getItem('userLanguage');
    const language = storedLanguage || DEFAULT_LANGUAGE;
    this.setLanguageTranslation(language);
  }

  async setLanguageTranslation(language: string) {
    const langKey = language ? language.split('-')[0] : DEFAULT_LANGUAGE;
    this.langJson = TRANSLATIONS[langKey] ? TRANSLATIONS[langKey] : TRANSLATIONS[DEFAULT_LANGUAGE];
  }

  getTranslatedValue(value: string) {
    return this.langJson[value] || value;
  }

  canTranslate(value: string) {
    return !!this.langJson[value];
  }
}
