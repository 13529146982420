<div *ngIf="editMode">
  <div class="input">
    <modus-text-input #tagTextInput
                      placeholder="{{ 'tagName' | translate }}"
                      clearable="true"
                      value="{{tagName}}"
                      max-length="25"
                      (valueChange)="onTagTextInputChange(tagTextInput.value)"></modus-text-input>
    <modus-button class="cancel-button"
                  color="danger"
                  (buttonClick)="onCancelButtonClick()">
      <div>
        <i class="modus-icons button-icon" aria-hidden="true">close</i>
      </div>
    </modus-button>
    <modus-button class="create-button"
                  [disabled]="!isSubmitButtonEnabled()"
                  (buttonClick)="onSubmitButtonClick()">
      <div>
        <i class="modus-icons button-icon" aria-hidden="true">check</i>
      </div>
    </modus-button>
  </div>
</div>
<div *ngIf="!editMode" class="dms-tag-editable-chip">
  <button class="tag-chip modus-chip style-solid small no-left-icon no-right-icon active"
          tabindex="0"
          type="button">
    <span>{{value.value | translate}}</span>
    <i class="modus-icons tag-chip-icon"
       aria-hidden="true"
       (click)="onEditIconClick()">pencil</i>
    <i class="modus-icons tag-chip-icon"
       aria-hidden="true"
       (click)="onDeleteIconClick()">delete</i>
  </button>
</div>
