import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {forkJoin, mergeMap, Observable, of} from 'rxjs';
import {expand, map, reduce, takeWhile} from 'rxjs/operators';
import {FileKeyProperties, PropertyKey, PropertyValue} from '../models/fileKeyProperties.model';
import {DMSConfigService} from './dmsconfig.service';
import {Page, PageInfo} from '../models/page.model';

export enum ExtendValues {
  VALUES = 'values'
}

export interface GetParameters  {
  extend?: ExtendValues[];
  page?: number;
  size?: number;
  sort?: string;
  search?: string;
}

export enum ParameterSortKey {
  KEY = 'key',
  ID = 'id'
}

export enum ParameterSortOrder {
  ASC = 'asc',
  DESC = 'desc'
}

@Injectable({
  providedIn: 'root'
})
export class PropertyDataService {

  private readonly QUERY_PARAM_KEY_EXPAND = 'expand';
  private readonly QUERY_PARAM_KEY_SEARCH = 'search';
  private readonly QUERY_PARAM_KEY_SIZE = 'size';
  private readonly QUERY_PARAM_KEY_PAGE = 'page';
  private readonly QUERY_PARAM_KEY_SORT = 'sort';
  private readonly PAGE_SIZE = 100;
  private readonly FIRST_PAGE = 0;

  private propertyApiUrl = `${this.dmsConfigService.getConfig().API_DMS_GATEWAY_URL}/property-keys`;

  constructor(private readonly http: HttpClient,
              private readonly dmsConfigService: DMSConfigService) {
  }

  getKeys$(parameters: GetParameters): Observable<Page<PropertyKey>> {
    const params = this.buildGetHttpParams(parameters);
    return this.http.get<Page<PropertyKey>>(this.propertyApiUrl, {params});
  }

  createPropertyKey$(key: string, values?: string[]): Observable<PropertyKey> {
    const body = (values && values.length > 0) ? {key, 'values': this.toValuesDto(values)} : {key};
    return this.http.post<PropertyKey>(this.propertyApiUrl, body);
  }

  deletePropertyKey$(id: number): Observable<void> {
    return this.http.delete<void>(`${this.propertyApiUrl}/${id}`);
  }

  updatePropertyKey$(id: number, propertyKey: PropertyKey): Observable<PropertyKey> {
    return this.http.put<PropertyKey>(`${this.propertyApiUrl}/${id}`, propertyKey);
  }

  editPropertyValue$(keyId: number, value: PropertyValue): Observable<PropertyValue> {
    return this.http.put<PropertyValue>(`${this.propertyApiUrl}/${keyId}/property-values/${value.id}`,
                                        {'value': value.value});
  }

  deletePropertyValue$(keyId: number, valueId: number): Observable<void> {
    return this.http.delete<void>(`${this.propertyApiUrl}/${keyId}/property-values/${valueId}`);
  }

  createPropertyValues$(keyId: number, values: string[]): Observable<PropertyValue[]> {
    return this.http.post<PropertyValue[]>(`${this.propertyApiUrl}/${keyId}/property-values/bulk`,
                                           values.map((value) => {
                                             return {value};
                                           }));
  }

  private readonly toValuesDto = (values: string[]): { 'value': string }[] => {
    return [...values].map(value => {
      return {'value': value};
    });
  }

  private buildGetHttpParams(parameters: GetParameters): HttpParams {
    let params = new HttpParams();
    if (!parameters) {
      return params;
    }
    const {extend, page, size, sort, search} = parameters;
    if (extend) {
      params = params.set(this.QUERY_PARAM_KEY_EXPAND, extend.join(','));
    }
    if (page) {
      params = params.set(this.QUERY_PARAM_KEY_PAGE, page.toString());
    }
    if (size) {
      params = params.set(this.QUERY_PARAM_KEY_SIZE, size.toString());
    }
    if (sort) {
      params = params.set(this.QUERY_PARAM_KEY_SORT, sort);
    }
    if (search) {
      params = params.set(this.QUERY_PARAM_KEY_SEARCH, search);
    }
    return params;
  }

  getValuesByKey(key: string): Observable<PropertyValue[]> {
    const initialParams = this.buildGetHttpParams({ extend: [ExtendValues.VALUES], page: this.FIRST_PAGE, size: this.PAGE_SIZE, sort: `${ParameterSortKey.ID},${ParameterSortOrder.ASC}` });

    return this.http.get<Page<PropertyValue>>(`${this.propertyApiUrl}/${key}/property-values`, { params: initialParams }).pipe(
      expand((response: Page<PropertyValue>) => {
        if (!response.page.last) {
          const nextParams = this.buildGetHttpParams({ extend: [ExtendValues.VALUES], page: response.page.number + 1, size: this.PAGE_SIZE, sort: `${ParameterSortKey.ID},${ParameterSortOrder.ASC}` });
          return this.http.get<Page<PropertyValue>>(`${this.propertyApiUrl}/${key}/property-values`, { params: nextParams });
        } else {
          return of(null);
        }
      }),
      takeWhile(response => response !== null),
      map((response: Page<PropertyValue>) => response.content),
      reduce((acc, content) => acc.concat(content), [])
    );
  }

  getValuesForAllKeys(parameters: GetParameters): Observable<FileKeyProperties[]> {
    return this.getKeys$(parameters).pipe(
      mergeMap((keys: Page<PropertyKey>) => {
        if (!keys.content || keys.content.length === 0) {
          return of([]); // Return an empty array if no keys are found
        }
        const requests = keys.content.map((iKey: PropertyKey) =>
          this.getValuesByKey(iKey.id.toString()).pipe(
            map(values => ({ id: iKey.id, key: iKey.key.toString(), values }))
          )
        );
        return forkJoin(requests);
      })
    );
  }
}
