import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DMSConfigService {
  private dmsConfig: any;

  constructor(private readonly http: HttpClient) {
  }

  loadDMSConfig() {
    return new Promise<void>((resolve, reject) => {
      //Need to change the time stamp value to build number part of the command line arguments or from the environment variable
      this.http.get('./config/dms-config.json?v='+(new Date).getTime()).subscribe({
        next: (config) => {
          if (config) {
            this.dmsConfig = config;
            this.addHeaderSidemenuScriptTag();
            resolve();
          }
        },
        error: (error) => {
          reject('Error loading dms-config.json');
        },
      });
    });
  }

  getConfig() {
    return this.dmsConfig;
  }

  addHeaderSidemenuScriptTag() {
    const header = document.createElement('script');
    header.type = 'text/javascript';
    header.src = this.dmsConfig.AIO_BASE_URL + this.dmsConfig.AIO_HEADER_URL;
    document.getElementsByTagName('head')[0].appendChild(header);

    const sideMenu = document.createElement('script');
    sideMenu.type = 'text/javascript';
    sideMenu.src =
      this.dmsConfig.AIO_BASE_URL + this.dmsConfig.AIO_SIDE_MENU_URL;
    document.getElementsByTagName('head')[0].appendChild(sideMenu);
  }
}
