import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable, Subject} from 'rxjs';

@Component({
             selector: 'dms-confirmation-dialog',
             templateUrl: './deletion-confirmation-dialog.component.html',
             styleUrls: ['./deletion-confirmation-dialog.component.css']
           })
export class DeletionConfirmationDialogComponent implements OnDestroy {

  private readonly deletionSubject: Subject<{ showAgain: boolean }> = new Subject();
  protected checked = false;

  constructor(public dialogRef: MatDialogRef<DeletionConfirmationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public readonly data: { title: string, message: string, subMessage: string }) {
  }

  ngOnDestroy() {
    this.deletionSubject.complete();
  }

  protected changeValue(value) {
    this.checked = !value;
  }

  protected get title() {
    return this.data.title;
  }

  protected get message() {
    return this.data.message;
  }

  protected get subMessage() {
    return this.data.subMessage;
  }

  protected onCancelClick(): void {
    this.deletionSubject.complete();
    this.dialogRef.close();
  }

  protected onDeleteClick(): void {
    this.deletionSubject.next({
                                showAgain: !this.checked,
                              });
    this.deletionSubject.complete();
    this.dialogRef.close();
  }

  deletion$(): Observable<{ showAgain: boolean }> {
    return this.deletionSubject.asObservable();
  }
}
