<ng-container *ngIf="displayDefaultActions(); else nonDefaultActions">
  <span class="action-icons">
    <mat-icon class="modus-icons add-icon"
              aria-hidden="true"
              *ngIf="tagAdditionEnabled"
              (click)="onAdditionButtonClick()">
      add_circle
    </mat-icon>
    <mat-icon class="modus-icons delete-icon"
              aria-hidden="true"
              (click)="onDeletionButtonClick()">
      delete
    </mat-icon>
  </span>
</ng-container>

<ng-template #nonDefaultActions>
  <div class="actions-container">
    <modus-button class="cancel-button"
                  color="danger"
                  (buttonClick)="onCancelButtonClick()">
      <div>
        <i class="modus-icons" aria-hidden="true">close</i>
      </div>
    </modus-button>
    <modus-button class="create-button"
                  [disabled]="!submitEnabled"
                  (buttonClick)="onSubmitButtonClick()">
      <div>
        <i class="modus-icons" aria-hidden="true">check</i>
      </div>
    </modus-button>
  </div>
</ng-template>
