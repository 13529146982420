import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

export enum ActionCellEventType {
  PROPERTY_DELETION_REQUEST,
  PROPERTY_CREATION_SUBMIT,
  PROPERTY_CREATION_CANCEL,
  TAG_ADDITION_REQUEST,
  TAG_ADDITION_SUBMIT,
  TAG_ADDITION_CANCEL,
  CATEGORY_EDITION_SUBMIT,
  CATEGORY_EDITION_CANCEL,
}

export interface ActionCellEvent {
  type: ActionCellEventType;
  key?: {
    id: number,
  };
}

@Injectable()
export class ActionCellService {

  private readonly actionCellEventSubject = new Subject<ActionCellEvent>;

  public actionCellEvent$(): Observable<ActionCellEvent> {
    return this.actionCellEventSubject.asObservable();
  }

  public publishCellElementEvent(event: ActionCellEvent): void {
    this.actionCellEventSubject.next(event);
  }
}
