import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CommonConfig} from '../../shared/config/common-config';
import {DocMgmtService} from '../../../services/doc-mgmt.service';
import {RightPanelService} from '../../../services/right-panel.service';
import {Subject, takeUntil} from 'rxjs';
import {FileKeyProperties, PropertyValue} from '../../../models/fileKeyProperties.model';
import {DateRangeValidator, END_DATE_KEY, START_DATE_KEY} from '../../shared/date-range-validator';
import {TranslateService} from '@ngx-translate/core';
import {HandleErrorService} from '../../../services/handle-error.service';
import {ExtendValues, ParameterSortKey, ParameterSortOrder, PropertyDataService} from '../../../services/property-data.service';
import {FilterService} from '../../../services/filter.service';

@Component({
  selector: 'dms-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.css']
})
export class FiltersComponent implements OnInit, OnDestroy {

  @ViewChild('startDateInput') startDateInput: ElementRef;
  @ViewChild('endDateInput') endDateInput: ElementRef;

  isShowFilter: boolean;
  pageNumber: number;
  filterValue: string;
  optionsList: FileKeyProperties[];
  form: FormGroup;
  countries: PropertyValue[] = [];
  documentTypes: PropertyValue[] = [];
  departments: PropertyValue[] = [];
  languages: PropertyValue[] = [];
  errorMessage: string;
  startDate: string;
  endDate: string;
  private isResetting = false;
  private readonly PAGE_SIZE = 20;
  private readonly FIRST_PAGE = 0;
  private readonly COLUMN_OPTIONS_SORT_PROPERTY = ParameterSortKey.ID;
  private readonly COLUMN_OPTIONS_SORT_ORDER = ParameterSortOrder.ASC;

  private readonly stop$: Subject<void> = new Subject<void>();

  constructor(private docMgmtService: DocMgmtService,
              private rightPanelService: RightPanelService,
              private propertyDataService: PropertyDataService,
              private translateService: TranslateService,
              private handleErrorService: HandleErrorService,
              private filterService: FilterService) {
    this.form = new FormGroup({
      startDate: new FormControl(null, Validators.required),
      endDate: new FormControl(null, Validators.required),
      country: new FormControl([]),
      language: new FormControl([]),
      documentType: new FormControl([]),
      department: new FormControl([]),
    }, {validators: DateRangeValidator.dateRangeValidator(new CommonConfig())});
  }

  formFieldConfigs: FormFieldConfig[] = [
    { name: 'country', label: 'Country', placeholderVisible: true, options: [] },
    { name: 'department', label: 'Department', placeholderVisible: true, options: [] },
    { name: 'documentType', label: 'Document Type', placeholderVisible: true, options: [] },
    { name: 'language', label: 'Language', placeholderVisible: true, options: [] }
  ];

  ngOnInit() {
    this.rightPanelService.filterEvent.subscribe((event) => {
      if (event.showFilter) {
        this.isShowFilter = true;
        this.rightPanelService.isPanelOpen.next(true);
      }
      if (event.reset && !this.isResetting) {
        this.isShowFilter = false;
        this.onReset();
      }
    });

    this.propertyDataService.getValuesForAllKeys({
      extend: [ExtendValues.VALUES],
      page: this.FIRST_PAGE,
      size: this.PAGE_SIZE,
      sort: `${this.COLUMN_OPTIONS_SORT_PROPERTY},${this.COLUMN_OPTIONS_SORT_ORDER}`,
      }).pipe(
      takeUntil(this.stop$)
    ).subscribe({
      next: (responses: FileKeyProperties[]) => {
        this.optionsList = responses;
        this.formFieldConfigs.forEach(fieldConfig => {
          fieldConfig.options = this.getOptionsByKey(this.capitalizeFirstLetter(fieldConfig.label));
        });
      },
      error: (error) => {
        this.handleErrorService.displayErrorDialog(this.translateService.getTranslation('unableToLoad'), '');
      }
    });
    this.loadFilterValues();
  }

  private getOptionsByKey(keyName: string): PropertyValue[] {
    // @ts-ignore
    const keyEntry = this.optionsList.find(item => item && item.key === keyName);
    return keyEntry ? keyEntry.values : [];
  }

  checkPlaceHolder(controlName: string) {
    const fieldConfig = this.formFieldConfigs.find(field => field.name === controlName);

    if (fieldConfig) {
      const controlValue = this.form.get(controlName)?.value;
      if (controlValue && controlValue.length !== 0) {
        fieldConfig.placeholderVisible = false;
      } else {
        setTimeout(() => {
          fieldConfig.placeholderVisible = true;
        }, 1000);
      }
    }
  }

  hidePlaceHolder(controlName: string) {
    const fieldConfig = this.formFieldConfigs.find(field => field.name === controlName);
    if (fieldConfig) {
      fieldConfig.placeholderVisible = false;
    }
  }

  private loadFilterValues(): void {
    const savedFilterValues = localStorage.getItem('filterValues');
    if (savedFilterValues) {
      const formValues = JSON.parse(savedFilterValues);
      this.formFieldConfigs.forEach(fieldConfig => {
        if (formValues[fieldConfig.name] && formValues[fieldConfig.name].length > 0) {
          fieldConfig.placeholderVisible = false;
        }
      });
      if (formValues.startDate) {
        this.startDate = this.formatISODateToString(formValues.startDate);
        this.form.get(START_DATE_KEY).setValue(this.startDate);
      }
      if (formValues.endDate) {
        this.endDate = this.formatISODateToString(formValues.endDate);
        this.form.get(END_DATE_KEY).setValue(this.endDate);
      }

      if (this.countries.length && this.documentTypes.length && this.departments.length && this.languages.length) {
        this.form.patchValue(formValues);
      } else {
        setTimeout(() => this.form.patchValue(formValues), 100);
      }
    }
  }

  private formatISODateToString(isoDate: string): string {
    const dateObj = new Date(isoDate);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();
    return `${year}-${month}-${day}`;
  }

  onStartDateChanged(startDateValue: Date): void {
    this.form.get(START_DATE_KEY).setValue(startDateValue);
  }

  onEndDateChanged(endDateValue: Date): void {
    this.form.get(END_DATE_KEY).setValue(endDateValue);
  }

  onFilter() {
    if (this.formValidation()) {
      const formValues = this.form.value;
      this.saveFilterValues();
      const propertyValues = [
        ...(formValues.country || []),
        ...(formValues.language || []),
        ...(formValues.documentType || []),
        ...(formValues.department || [])
      ];
      const startDate = formValues.startDate ? new Date(formValues.startDate) : null;
      startDate?.setHours(0, 0, 0, 0);

      const endDate = formValues.endDate ? new Date(formValues.endDate) : null;
      endDate?.setHours(23, 59, 59, 999);

      this.filterService.setStartDate(startDate);
      this.filterService.setEndDate(endDate);
      this.filterService.setPropertyValuesIds(propertyValues);

      this.docMgmtService.getFileMetadata({
        page: 0,
        filterValue: this.filterValue,
        startDate: startDate?.getTime(),
        endDate: endDate?.getTime(),
        propertyValues: propertyValues
      });
      this.rightPanelService.filterEvent.emit({showFilter: true, reset: false, isFilterApplied: true});
    }
  }

  private formValidation(): boolean {
    const errorKeys = ['startDateGreaterThanEndDate', 'startDateNotSelected', 'timeExceed'];
    for (const key of errorKeys) {
      if (this.form.errors?.[key]) {
        this.errorMessage = this.translateService.instant(key);
        return false;
      }
    }
    return true;
  }

  private saveFilterValues(): void {
    const formValues = this.form.value;
    localStorage.setItem('filterValues', JSON.stringify(formValues));
  }

  private capitalizeFirstLetter(str: string): string {
    if (!str) { return str; }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }


  onReset() {
    this.form.reset();
    this.startDate = null;
    this.endDate = null;
    this.startDateInput.nativeElement.value = '';
    this.endDateInput.nativeElement.value = '';
    this.filterService.setStartDate(null);
    this.filterService.setEndDate(null);
    this.filterService.setPropertyValuesIds([]);
    localStorage.removeItem('filterValues');

    this.formFieldConfigs.forEach(fieldConfig => {
      fieldConfig.placeholderVisible = true;
    });

    this.rightPanelService.filterEvent.emit({showFilter: false, reset: false, isFilterApplied: false});
    this.docMgmtService.getFileMetadata();
    this.isResetting = false;
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }
}

interface FormFieldConfig {
  name: string;
  label: string;
  placeholderVisible: boolean;
  options: PropertyValue[];
}
