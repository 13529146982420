import {PropertyValue} from '../../../../models/fileKeyProperties.model';

export class TagCollection {

  private readonly existingTag: Map<number, string>;
  private readonly existingTagsNames: Set<string>;
  private pendingTagsNames: Set<string>;

  constructor(propertyValues?: PropertyValue[]) {
    this.existingTag = new Map();
    this.existingTagsNames = new Set<string>();
    if (propertyValues !== undefined) {
      propertyValues.forEach(({id, value}) => {
        this.existingTag.set(id, value);
        this.existingTagsNames.add(value);
      });
    }
    this.pendingTagsNames = new Set<string>();
  }

  public addPendingTagName(value: string): void {
    this.pendingTagsNames.add(value);
  }

  public addExistingTags(propertyValues: PropertyValue[]): void {
    propertyValues.filter(({value}) => this.pendingTagsNames.has(value))
                  .forEach(({id, value}) => {
                    this.pendingTagsNames.delete(value);
                    this.existingTag.set(id, value);
                    this.existingTagsNames.add(value);
                  });
  }

  public includes(value: string): boolean {
    return this.existingTagsNames.has(value) || this.pendingTagsNames.has(value);
  }

  public pendingCollectionIncludes(value: string): boolean {
    return this.pendingTagsNames.has(value);
  }

  public getPendingTagsNames(): string[] {
    return [...this.pendingTagsNames.values()];
  }

  public getExistingTags(): { id: number, value: string }[] {
    const result = [];
    this.existingTag.forEach((value: string, key: number) => {
      result.push({id: key, value: value});
    });
    return result;
  }

  public size(): number {
    return this.existingTag.size + this.pendingTagsNames.size;
  }

  public existingTagsSize(): number {
    return this.existingTag.size;
  }

  public pendingTagsSize(): number {
    return this.pendingTagsNames.size;
  }

  public resetPendingTags(): void {
    this.pendingTagsNames = new Set();
  }

  public removeFromPending(value: string): void {
    this.pendingTagsNames.delete(value);
  }

  public removeFromExisting(key: number): void {
    const value = this.existingTag.get(key);
    this.existingTag.delete(key);
    this.existingTagsNames.delete(value);
  }

  public replaceExistingTag(key: number, newValue: string): void {
    const value = this.existingTag.get(key);
    this.existingTag.set(key, newValue);
    this.existingTagsNames.delete(value);
    this.existingTagsNames.add(newValue);
  }
}
