import {ToastrService} from 'ngx-toastr';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpStatusCode} from '@angular/common/http';

const service = 'external service';

@Injectable()
export class HandleErrorService {
  private static readonly ERROR_IDS_WITHOUT_DISPLAY = new Set(['3']);
  errorMessage: string;

  constructor(
    private readonly toastr: ToastrService,
    private readonly router: Router
  ) {
  }

  handleError(error) {
    const requestedUrl = error.url;
    if (
      requestedUrl.indexOf('/oauth/token') >= 0 ||
      requestedUrl.indexOf('/openid-configuration') >= 0
    ) {
      this.errorMessage = 'tidAuthError';
      this.router.navigate(['/error']);
      return;
    }
    if (
      ((requestedUrl.includes('/me/v2/applications')  || requestedUrl.includes('api/gateway-dms/')) &&
        error.status === HttpStatusCode.Forbidden)
    ) {
      this.errorMessage = 'noAppRole';
      this.router.navigate(['/error']);
      return;
    }
    let apiEndPoint = '';
    if (requestedUrl.includes('fleet')) {
      apiEndPoint = requestedUrl
        .substring(requestedUrl.indexOf('fleet'))
        .split('/')[1];
    }
    if (requestedUrl.includes('/files/') || requestedUrl.includes('/incoming-files/')) {
      return;
    }
    if (error?.error?.errorId === undefined ||
        !HandleErrorService.ERROR_IDS_WITHOUT_DISPLAY.has(error?.error?.errorId)) {
      this.displayErrorMessage(error, apiEndPoint);
    }
  }

  displayErrorDialog(errorMessage, errorTitle) {
    this.toastr.error(errorMessage, errorTitle, {
      disableTimeOut: false,
      tapToDismiss: true,
      toastClass: 'toast border-red',
      closeButton: true,
    });
  }

  private displayErrorMessage(error, apiEndPoint) {
    let errorTitle = '';
    let errorMessage = '';

    if (error.status === 0) {
      errorMessage = this.getConnectionRefusedMessage(apiEndPoint);
    } else if (this.isClientOrServerError(error)) {
      errorTitle = ERROR_CODE[error.status];
      errorMessage = this.getServiceName(apiEndPoint);
    } else if (error.status >= 503) {
      errorTitle = 'Oops!!! Resource unavailable ';
      errorMessage = `Something wrong with ${service}`;
    } else {
      errorMessage = this.getErrorMessage(error, apiEndPoint);
      errorTitle = errorMessage ? '' : 'Oops!!! Something went wrong in ';
    }

    this.displayErrorDialog(errorMessage, errorTitle);
  }

  private getConnectionRefusedMessage(apiEndPoint) {
    return `Connection refused to ${this.getServiceName(apiEndPoint)}`;
  }

  private isClientOrServerError(error) {
    return error.status >= 400 && !error.name && !error.error.message;
  }

  private getServiceName(apiEndPoint) {
    return `${
      END_POINT[apiEndPoint] ? END_POINT[apiEndPoint] : service
    }`;
  }

  private getErrorMessage(error, apiEndPoint) {
    if (error.error && error.error.message) {
      return `${error.error.message}`;
    } else {
      return `${
        this.getServiceName(apiEndPoint)
      }`;
    }
  }
}

const ERROR_CODE = {
  '401': '401 Unauthorized',
  '500': '500 Internal Server Error',
  '404': '404 Not Found',
};

const END_POINT = {
  dmsfile: 'Files service',
  docmgmt: 'DMS service',
  drivers: 'Driver service',
  terminals: 'Terminal service',
  files: 'Fleet trace service',
};
