<div #parentElement class="container">

  <ng-container *ngIf="!displayTagsWithExpansion(); else expandedContainer">
    <span *ngFor="let value of overviewTags; trackBy: trackTags"
          class="chip-span">
      <ng-container *ngIf="global; else editableChips">
        <modus-chip class="tag-chip"
                    size="small"
                    value="{{value.value | translate}}">
        </modus-chip>
      </ng-container>
      <ng-template #editableChips>
        <dms-tag-editable-chip [value]="value"
                               [keyId]="keyId"
                               (editChip)="onEditChip($event)"
                               (deleteChip)="onDeleteChip($event)">
        </dms-tag-editable-chip>
      </ng-template>
    </span>
    <span *ngIf="totalRemainingTags > 0"
          class="chip-span">
      <button class="modus-chip style-solid small no-left-icon no-right-icon active"
              tabindex="0"
              type="button"
              (click)="onExpansionChipClick()">
        <span>+ {{this.totalRemainingTags}}</span>
        <i class="modus-icons expand-icons" aria-hidden="true">expand_more</i>
      </button>
    </span>
  </ng-container>

  <!--existing tags with expansion-->

  <ng-template #expandedContainer>
    <span *ngFor="let value of tags; trackBy: trackTags"
          class="chip-span">
      <ng-container *ngIf="global; else editableChips">
        <modus-chip class="tag-chip"
                    size="small"
                    value="{{value.value | translate}}">
        </modus-chip>
      </ng-container>
      <ng-template #editableChips>
        <dms-tag-editable-chip [value]="value"
                               [keyId]="keyId"
                               (editChip)="onEditChip($event)"
                               (deleteChip)="onDeleteChip($event)">
        </dms-tag-editable-chip>
       </ng-template>
    </span>

    <span *ngIf="displayCollapseButton()"
          class="chip-span">
      <button class="modus-chip style-solid small no-left-icon no-right-icon active"
              tabindex="0"
              type="button"
              (click)="onCollapseTagsClick()">
        <span>{{'collapse' | translate}}</span>
        <i class="modus-icons expand-icons" aria-hidden="true">expand_less</i>
      </button>
    </span>
  </ng-template>

  <!--new tags-->

  <span *ngFor="let value of pendingTags; trackBy: trackPendingTags"
        class="chip-span">
    <dms-tag-creatable-chip [value]="value | translate"
                            (closeClickEmitter)="onCloseTagClick($event)">
    </dms-tag-creatable-chip>
  </span>

  <div class="input"
       *ngIf="displayTagAdditionElements()">
    <modus-text-input #tagTextInput
                      placeholder="{{ 'tagName' | translate }}"
                      clearable="true"
                      value="{{currentTagName}}"
                      max-length="25"
                      (valueChange)="onTagTextInputChange(tagTextInput.value)"></modus-text-input>
    <modus-button class="add-button"
                  color="secondary"
                  (buttonClick)="onAddButtonClick()"
                  [disabled]="!isAllowedToAddTag()">
      <div>
        <i class="modus-icons create-icon" aria-hidden="true">add</i>
      </div>
    </modus-button>
  </div>
</div>
