import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

export enum CategoryElementEventType {
  CATEGORY_WITHOUT_TEXT,
  CATEGORY_WITH_TEXT,
  CATEGORY_SUBMIT,
  CATEGORY_EDIT_REQUEST
}

export interface CategoryElementEvent {
  type: CategoryElementEventType;
  key: {
    id?: number,
    key?: string,
  };
}

@Injectable()
export class CategoryCellService {

  private readonly categoryElementEventSubject = new Subject<CategoryElementEvent>;

  public categoryElementEvent$(): Observable<CategoryElementEvent> {
    return this.categoryElementEventSubject.asObservable();
  }

  public publishCategoryElementEvent(event: CategoryElementEvent): void {
    this.categoryElementEventSubject.next(event);
  }
}
