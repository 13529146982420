import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatOptionSelectionChange} from '@angular/material/core';
import {Observable, startWith} from 'rxjs';
import {map} from 'rxjs/operators';
import {PropertyKey, PropertyValue} from '../../../models/fileKeyProperties.model';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.css'],
})
export class MultiSelectComponent implements OnInit, OnChanges {
  multiSelectCtrl = new FormControl<PropertyValue[]>({
    value: [],
    disabled: false
  });
  searchCtrl = new FormControl();
  filteredValues: Observable<PropertyValue[]>;

  @Input() optionsList: PropertyValue[] = [];
  @Input() label: PropertyKey = {id: 0, global: false, key: ''};
  @Input() prefilledValues: PropertyValue[] = [];
  @Output() selectionChange = new EventEmitter<PropertyValue[]>();

  ngOnChanges() {
    this.multiSelectCtrl.setValue(this.prefilledValues);
  }

  ngOnInit(): void {
    this.filteredValues = this.searchCtrl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filter(value || '', this.optionsList))
    );
    if (this.prefilledValues && this.prefilledValues.length > 0) {
      this.multiSelectCtrl.setValue(this.prefilledValues);
    }
  }

  onSelectionChange(event: MatOptionSelectionChange) {
    let values: PropertyValue[] = this.multiSelectCtrl.value || ([] as PropertyValue[]);
    if (event.isUserInput) {
      const valExists = values.find((val) => val.id === event.source.value.id);
      if (event.source.selected && !valExists) {
        values.push(event.source.value);
      } else if (!event.source.selected && valExists) {
        values = values.filter((value) => value.id !== event.source.value.id);
      }
      this.multiSelectCtrl?.setValue(values);
      this.selectionChange.emit(values);
    }
  }

  compareTwoObjValues(p1: any, p2: any): boolean {
    if (p1 && p2) {
      return p1.id === p2.id;
    }
    return false;
  }

  private filter(value: string, options: PropertyValue[]): PropertyValue[] {
    const filterValue = value.toLowerCase();
    return options?.filter((option) =>
      option.value.toLowerCase().includes(filterValue)
    );
  }
}
