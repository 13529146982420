import {RightPanelService} from '../../../services/right-panel.service';
import {TerminalService} from '../../../services/terminal.service';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TableConfig} from '../../shared/table/table-config/table-config';
import {Subject, takeUntil} from 'rxjs';
import {TableComponent} from '../../shared/table/table.component';
import {AdminLoginService} from '../../../services/admin-login.service';

@Component({
  selector: 'dms-unit',
  templateUrl: './unit.component.html'
})


export class UnitComponent implements OnInit, OnDestroy {

  @ViewChild('unitTable') unitTable: TableComponent;

  tableConfig: TableConfig;
  tableData: any[] = null;
  private readonly stop$: Subject<void> = new Subject<void>();

  constructor(private readonly terminalService: TerminalService,
              private readonly rightPanelService: RightPanelService,
              private readonly adminLoginService: AdminLoginService) {
  }

  ngOnInit(): void {
    this.subscribeToCustomerSelectionChange();
    this.tableConfig = new TableConfig();
    this.tableConfig.showCheckbox = false;
    this.tableConfig.rowClickAction = true;
    this.tableConfig.displayedColumnNames = ['name', 'deviceType', 'groupName'];
    this.terminalService.getTerminals();
    this.terminalService.terminals.pipe(takeUntil(this.stop$)).subscribe(terminalsList => {
      this.tableData = terminalsList;
    });
  }

  private subscribeToCustomerSelectionChange(): void {
    this.adminLoginService.customerSelectionChanged$
      .pipe(takeUntil(this.stop$))
      .subscribe(() => {
        this.rightPanelService.closePanel();
        this.terminalService.getTerminals();
      });
  }

  ngOnDestroy() {
    this.stopSubscriptions();
  }

  private stopSubscriptions(): void {
    this.stop$.next();
    this.stop$.complete();
  }

  rowSelected(row) {
    if (row) {
      this.rightPanelService.selectedUnit.next(row);
    }
  }

  clearTableSelection() {
    if (this.unitTable) {
      this.unitTable.clearSelection();
    }
  }

}
