import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
             selector: 'dms-tag-creatable-chip',
             templateUrl: './tag-creatable-chip.component.html',
             styleUrls: ['./tag-creatable-chip.component.css']
           })
export class TagCreatableChipComponent {

  @Input() protected readonly value: string;

  @Output() protected readonly closeClickEmitter = new EventEmitter<string>();

  protected onCloseClick(): void {
    this.closeClickEmitter.emit(this.value);
  }
}
